import { AppState } from '../../root-reducer';

export const selectSentinelIsBoxSelectActive = (state: AppState) => state.sentinelDomain.sentinelIsBoxSelectActive;

export const selectSentinelSelectedAOI = (state: AppState) => state.sentinelDomain.sentinelSelectedAOI;

export const selectSentinelMapZoom = (state: AppState) => state.sentinelDomain.sentinelMapZoom;

export const selectSentinelSelectedFeature = (state: AppState) => state.sentinelDomain.sentinelSelectedFeature;

export const selectSentinelSelectedFeatureOpacity = (state: AppState) =>
    state.sentinelDomain.sentinelSelectedFeatureOpacity;

export const selectIsSentinelDownloadOpen = (state: AppState) => state.sentinelDomain.sentinelIsDownloadOpen;

export const selectIsSentinelShareOpen = (state: AppState) => state.sentinelDomain.sentinelIsShareOpen;

export const selectSentinelOpacity = (state: AppState) => state.sentinelDomain.sentinelLayerOpacity;

export const selectSentinelFeatures = (state: AppState) => state.sentinelDomain.sentinelFeatures;

export const selectSentinelError = (state: AppState) => state.sentinelDomain.sentinelError;

export const selectSentinelFeaturesLoading = (state: AppState) => state.sentinelDomain.sentinelFeaturesLoading;

export const selectSentinelFeatureLoading = (state: AppState) => state.sentinelDomain.sentinelFeatureLoading;
