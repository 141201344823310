import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import {
    actionSentinelBeginBoxSelect,
    actionSentinelChangeOpacity,
    actionSentinelClearFeatures,
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeatures,
    actionSentinelFetchFeaturesError,
    actionSentinelFetchFeaturesSuccess,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { PulseLoader } from '../../../Shared/pulse-loader';
import DrawerHint from '../../drawer-hint';
import {
    SatelliteSubmitButton,
    Content,
    EmptyTeaserTitle,
    Logo,
    LogoContainer,
    ShowMoreButton,
    ShowMoreContainer,
    TeaserIcon,
    TeaserText,
} from '../satellite-drawer-styles';
import SatelliteLoading from '../satellite-loading';
import SentinelItem from './sentinel-item';
import SentinelLowResolutionNotice from './sentinel-low-resolution-notice';

import { SideDrawerBackButton } from '../../SideDrawer/Shared/side-drawer-buttons';
import DrawerLogin from '../../Shared/drawer-login';
import { sentinelDefaultSideDrawerEvalScripts, trueColor } from './sentinel-default-evalscripts';
import SentinelDrawerFilter from './sentinel-drawer-filter';
import SentinelOpacitySlider from './sentinel-opacity-slider';
import AdsenseComponent from '../../../Adsense/adsense';
import ApiCfSentinel, { EvalScript } from '../../../../api/api-cf-sentinel';
import { DateRange, last30Days } from './Shared/date-filter-util';

const moment = require('moment');

const SentinelDrawer = () => {
    const bbox = useSelector(selectSentinelSelectedAOI);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSentinelBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const resetSelectedAOI = () => dispatch(actionSentinelResetSelectedAOI());
    const resetSelectedSentinelFeature = () => dispatch(actionSentinelResetSelectedFeature());
    const clearSentinelFeatures = () => dispatch(actionSentinelClearFeatures());

    const isLoggedIn = useSelector(selectLoggedIn);

    const [userResolution, setUserResolution] = useState<number | undefined>(undefined);
    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [evalScript, setEvalScript] = useState<EvalScript>(selectedSentinelFeature?.evalScript ?? trueColor);
    const [dropdownEvalScript, setDropdownEvalScript] = useState<EvalScript>(
        selectedSentinelFeature?.evalScript ?? trueColor
    );
    const [sentinelDefaultSideDrawerScripts, setSentinelDefaultSideDrawerScripts] = useState<EvalScript[]>(
        sentinelDefaultSideDrawerEvalScripts
    );
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const invalidateSentinelResults = () => {
        clearSentinelFeatures();
        resetSelectedAOI();
        resetSelectedSentinelFeature();
        endBoxSelect();
        setDateRange(last30Days);
    };

    useEffect(() => {
        if (selectedSentinelFeature && !sentinelFeaturesLoading && !sentinelFeatures?.length) {
            const today = moment();
            const selectedSentinelFeatureDate = moment(selectedSentinelFeature.date);

            if (today.diff(selectedSentinelFeatureDate, 'days') <= 30) {
                setDateRange(last30Days);
            } else {
                const startDate = selectedSentinelFeatureDate.clone().startOf('month');
                const endDate = selectedSentinelFeatureDate.clone().endOf('month');

                setDateRange({
                    endDate: endDate.toDate(),
                    startDate: startDate.toDate(),
                    displayText: startDate.format('MMMM YYYY'),
                });
            }
        } else {
            setDateRange(last30Days);
        }

        return () => {
            invalidateSentinelResults();
            dispatch(actionSentinelFetchFeaturesSuccess(undefined));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //reset opacity
        dispatch(actionSentinelChangeOpacity(undefined));
        if (bbox && dateRange && !sentinelFeaturesLoading && !showMoreLoading) {
            requestAnimationFrame(() => {
                handleAddingEvalScriptToDropdown(selectedSentinelFeature?.evalScript || evalScript);
            });

            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    [evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    selectedSentinelFeature?.evalScript || evalScript,
                    userResolution,
                    !isBoxSelectActive
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bbox, evalScript, dateRange, userResolution]);

    useEffect(() => {
        if (selectedSentinelFeature?.evalScript) {
            handleAddingEvalScriptToDropdown(selectedSentinelFeature.evalScript);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSentinelFeature?.evalScript]);

    useEffect(() => {
        setUserResolution(selectedSentinelFeature?.resolution);
    }, [selectedSentinelFeature?.resolution]);

    useEffect(() => {
        if (sentinelFeatures) {
            setShowMoreLoading(false);
        }
    }, [sentinelFeatures]);

    const handleAddingEvalScriptToDropdown = (evalScript: EvalScript) => {
        const evalScripts = [...sentinelDefaultSideDrawerEvalScripts, evalScript];

        const uniqueEvalScripts = evalScripts.filter(
            (script, index, self) => index === self.findIndex((s) => s.name === script.name)
        );
        setDropdownEvalScript(evalScript);
        setSentinelDefaultSideDrawerScripts(uniqueEvalScripts);
    };

    const handleShowMore = async () => {
        Analytics.Event('Satellite - Sentinel', 'Clicked show more', selectedSentinelFeature?.evalScript.name);

        if (showMoreLoading) {
            return;
        }

        const startDate = moment(dateRange.startDate).subtract(1, 'day').toDate();
        const newStartDate = moment(startDate).subtract(1, 'months').toDate();
        const newDateRange = { ...dateRange, startDate: newStartDate };
        setDateRange(newDateRange);

        setEvalScript(dropdownEvalScript);
        setShowMoreLoading(true);

        if (bbox) {
            try {
                const results = await ApiCfSentinel.searchSentinelFeatures({
                    bounds: bbox,
                    dateFrom: newStartDate,
                    dateTo: dateRange.startDate,
                    satellites: [
                        selectedSentinelFeature?.evalScript.sentinelSatelliteType ?? evalScript.sentinelSatelliteType,
                    ],
                    limit: 30,
                    evalScript: selectedSentinelFeature?.evalScript,
                    userSelectedResolution: userResolution ?? selectedSentinelFeature?.resolution,
                });

                if (!results?.length) {
                    throw new Error('No data available. Please select a different area.');
                }

                const features = sentinelFeatures ? [...sentinelFeatures, ...results] : results;

                dispatch(actionSentinelFetchFeaturesSuccess(features));
            } catch (error) {
                dispatch(actionSentinelFetchFeaturesError(error));
            }
        }
    };

    const handleSelectDateRange = (dateRange: DateRange) => {
        setDateRange(dateRange);
        setShowMoreLoading(true);
        if (bbox) {
            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    [selectedSentinelFeature?.evalScript.sentinelSatelliteType ?? evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    selectedSentinelFeature?.evalScript ?? evalScript,
                    userResolution ?? selectedSentinelFeature?.resolution,
                    !isBoxSelectActive
                )
            );
        }
    };

    const isLowResolution = sentinelFeatures && sentinelFeatures[0]?.resolution !== 10;

    // TODO UPDATE this catches some edge cases but can it be simplifies now?
    const reset = () => {
        UriHelper.removeAllSentinelParametersFromURI();
        UriHelper.removeParameterFromUri('shareId');
        ApiCfSentinel.clearAllCancelTokens('user backed out');
        if (sentinelFeatures) {
            invalidateSentinelResults();
        } else {
            clearSentinelFeatures();
            resetSelectedAOI();
            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
            endBoxSelect();
            Analytics.Event('Satellite - Sentinel', 'Clicked back arrow');
        }
    };

    if (!isLoggedIn) {
        return (
            <DrawerLogin
                logo="/assets/floating-drawer-satellite-icons/satellite-sentinel-logo.png"
                onLogin={(isUserLoggedIn) => {
                    if (!isUserLoggedIn) {
                        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
                    }
                }}
                onClickBackButton={reset}
            />
        );
    }

    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={reset} />

            <LogoContainer>
                <Logo src="/assets/floating-drawer-satellite-icons/satellite-sentinel-logo.png" />
            </LogoContainer>
            {!sentinelFeatures && !sentinelFeaturesLoading && sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        onClick={() => {
                            invalidateSentinelResults();
                            Analytics.Event('Satellite - Sentinel', 'Cleared Sentinel Feature');
                        }}
                    >
                        RESET AREA OF INTEREST
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {!sentinelFeaturesLoading && !sentinelFeatures?.length && !sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <TeaserText>Click the button and drag an Area of Interest (AOI).</TeaserText>
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        disabled={isBoxSelectActive}
                        onClick={() => {
                            ApiCfSentinel.clearAllCancelTokens('started new AOI');
                            clearSentinelFeatures();
                            resetSelectedSentinelFeature();

                            beginBoxSelect();
                            Analytics.Event('Satellite - Sentinel', 'Clicked draw area of interest');
                        }}
                    >
                        DRAW AOI
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {sentinelFeaturesLoading && !showMoreLoading && !sentinelError ? (
                <Content>
                    <SatelliteLoading />
                </Content>
            ) : null}

            {sentinelFeatures?.length && !sentinelError && (!sentinelFeaturesLoading || showMoreLoading) ? (
                <React.Fragment>
                    <SentinelDrawerFilter
                        onSelectEvalScript={setEvalScript}
                        onSelectDateRange={(dateRange) => handleSelectDateRange(dateRange)}
                        evalScript={dropdownEvalScript}
                        dateRange={dateRange}
                        defaultEvalScriptOptions={sentinelDefaultSideDrawerScripts}
                    />
                    {selectedSentinelFeature ? (
                        <SentinelOpacitySliderContainer>
                            <SentinelOpacitySlider />
                        </SentinelOpacitySliderContainer>
                    ) : null}
                    <SentinelContent>
                        {isLowResolution ? <SentinelLowResolutionNotice /> : null}

                        <SentinelItems
                            lowResVisible={isLowResolution ? true : false}
                            featureActive={!!selectedSentinelFeature}
                        >
                            {sentinelFeatures.map((t, index) => (
                                <SentinelItem
                                    key={index}
                                    feature={t}
                                    evalScript={selectedSentinelFeature?.evalScript ?? evalScript}
                                    onEvalScriptUpdate={setEvalScript}
                                />
                            ))}

                            <ShowMoreContainer>
                                {showMoreLoading ? (
                                    <ShowMoreButton>
                                        <PulseLoader />
                                    </ShowMoreButton>
                                ) : (
                                    <ShowMoreButton
                                        onClick={() => {
                                            handleShowMore();
                                            Analytics.Event(
                                                'Satellite - Sentinel',
                                                'Clicked show more',
                                                evalScript.name
                                            );
                                        }}
                                    >
                                        SHOW MORE
                                    </ShowMoreButton>
                                )}
                            </ShowMoreContainer>
                        </SentinelItems>
                    </SentinelContent>
                </React.Fragment>
            ) : null}
            {sentinelError ? (
                <DrawerHint error>{sentinelError instanceof Error ? sentinelError.message : sentinelError}</DrawerHint>
            ) : null}
            <AdsenseComponent
                style={{ display: 'block', width: '320px', height: '250px' }}
                client="ca-pub-2920378478344292"
                slot="4897379124"
                format="auto"
            />
        </React.Fragment>
    );
};

export default SentinelDrawer;

const SentinelContent = styled(Content)`
    display: flex;
    flex-direction: column;
`;

const calculateFlexBasis = (lowResVisible: boolean, featureActive: boolean) => {
    if (lowResVisible && featureActive) return 'calc(100vh - 370px)';
    if (!lowResVisible && featureActive) return 'calc(100vh - 305px)';
    if (lowResVisible && !featureActive) return 'calc(100vh - 330px)';
    return 'calc(100vh - 260px)';
};

const SentinelItems = styled.div<{ lowResVisible: boolean; featureActive: boolean }>`
    overflow-y: auto;
    flex-basis: ${({ lowResVisible, featureActive }) => calculateFlexBasis(lowResVisible, featureActive)};
    margin: 5px 0px 0px 5px;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const SentinelOpacitySliderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px;
`;
