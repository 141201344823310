import { useEffect } from 'react';
import styled from 'styled-components';
import { listingChipForType, ListingDTOWithChip } from '../../Drawer/SideDrawer/ListingsDrawer/listing-card-chip';
import MapSearchDrawListingCard from '../../Drawer/SideDrawer/ListingsDrawer/map-search-draw-listing-card';
import { PulseLoader } from '../../Shared/pulse-loader';

interface MapRibbonResultsProps {
    listings: ListingDTOWithChip[] | undefined;
    listingsCount: number;
    isLoading: boolean;
    onLoadMore: () => void;
    scrollEvent: React.UIEvent<HTMLDivElement> | undefined;
    onSelectListing: (listing: ListingDTOWithChip) => void;
}

const MapRibbonResults = (props: MapRibbonResultsProps) => {
    useEffect(() => {
        if (props.scrollEvent) {
            const target = props.scrollEvent.target as HTMLDivElement;
            if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                props.onLoadMore();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scrollEvent]);

    return (
        <ResultsContainer>
            {props.listings &&
                props.listings.map((listing) => (
                    <CardContainer key={listing.id} onClick={() => props.onSelectListing(listing)}>
                        <MapSearchDrawListingCard listing={listing} chip={listingChipForType(listing.chip)} />
                    </CardContainer>
                ))}

            {props.isLoading && <PulseLoader />}
        </ResultsContainer>
    );
};

export default MapRibbonResults;

const ResultsContainer = styled.div`
    overflow-y: auto;
    margin-top: 42px;
    margin-bottom: 75px;
    margin-left: 5px;
    pointer-events: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

export const CardContainer = styled.div`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
`;
