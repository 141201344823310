import ApiCfSentinel from '../../../../../api/api-cf-sentinel';
import UriHelper from '../../../../../lib/uri-helper';
import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';

const handleUpdateShortUrl = async (feature: SentinelFeature) => {
    if (!feature?.evalScript) return;

    try {
        const response = await ApiCfSentinel.postSentinelShortUrl(feature);
        if (typeof response === 'string') {
            UriHelper.addParametersToUri({ shareId: response });
        } else {
            console.error('Error updating short URL:', response.error);
        }
    } catch (error) {
        console.error('Failed to update short URL:', error);
    }
};

export const updateShortUrl = (selectedFeature?: SentinelFeature) => {
    if (!selectedFeature) {
        UriHelper.addParametersToUri({ shareId: null });
    } else {
        requestAnimationFrame(() => handleUpdateShortUrl(selectedFeature));
    }
};
