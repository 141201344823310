import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { EvalScript } from '../../../api/api-cf-sentinel';
import Analytics from '../../../lib/user-analytics';
import { useDispatch } from 'react-redux';
import { actionSentinelFetchFeaturesSuccess, actionSentinelSelectFeature } from '../../../store/Map/Sentinel/actions';
import { updateShortUrl } from '../../Drawer/Satellites/Sentinel/Shared/use-short-url';
import { DateRange, getDateOptions, last30Days } from '../../Drawer/Satellites/Sentinel/Shared/date-filter-util';

interface SentinelFilterProps {
    onSelectEvalScript: (evalScript: EvalScript) => void;
    evalScript: EvalScript;
    dateRange: DateRange;
    onSelectDateRange: (dateRange: DateRange) => void;
    defaultEvalScriptOptions: EvalScript[];
    isMobile?: boolean;
}

const MobileSatelliteFilter = (props: SentinelFilterProps) => {
    const [dates, setDates] = useState<DateRange[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateRange>(props.dateRange);
    const [selectedEvalScript, setSelectedEvalScript] = useState<EvalScript>(props.evalScript);

    const dispatch = useDispatch();

    const changeDateRange = (date: DateRange) => {
        Analytics.Event('Satellite - Sentinel - mobile', 'Date filter changed', date.displayText);
        setSelectedDate(date);
        props.onSelectDateRange(date);
    };

    const handleChangeEvalScript = (scriptName: string) => {
        Analytics.Event('Satellite - Sentinel - mobile', 'Changed EvalScript', scriptName);
        const selectedScript = props.defaultEvalScriptOptions.find((script) => script.name === scriptName);
        if (!selectedScript) return;
        updateShortUrl();
        dispatch(actionSentinelFetchFeaturesSuccess(undefined));
        dispatch(actionSentinelSelectFeature(undefined));
        props.onSelectEvalScript(selectedScript);
    };

    const evalScriptOptions = [
        ...props.defaultEvalScriptOptions.map((evalOption) => ({
            value: evalOption.name,
            label: evalOption.name,
            evalScript: evalOption,
        })),
    ];

    if (!evalScriptOptions.some((opt) => opt.value === selectedEvalScript?.name)) {
        evalScriptOptions.push({
            value: 'Custom Script',
            label: 'Custom Script',
            evalScript: selectedEvalScript,
        });
    }

    const selectedEvalOption = evalScriptOptions.find(
        (option) => option.evalScript.name === selectedEvalScript?.name
    ) || {
        value: 'Custom Script',
        label: 'Custom Script',
    };

    const dateRangeOptions = dates.map((date) => ({
        value: date.displayText,
        label: date.displayText,
    }));

    const selectedDateOption = dateRangeOptions.find((option) => option.value === selectedDate.displayText) || {
        value: last30Days.displayText,
        label: last30Days.displayText,
    };

    const dateOptions = useCallback(() => {
        return getDateOptions();
    }, []);

    useEffect(() => {
        setDates(dateOptions());
    }, [dateOptions]);

    useEffect(() => {
        if (props.evalScript) {
            setSelectedEvalScript(props.evalScript);
        }
    }, [props.evalScript]);

    useEffect(() => {
        if (props.dateRange) {
            setSelectedDate(props.dateRange);
        }
    }, [props.dateRange]);

    return (
        <Container>
            <FilterColumn>
                <SelectIcon>
                    <img src="/assets/sentinel-filter/layers.svg" />
                </SelectIcon>
                <Select
                    className="first-select-option-list"
                    data-testid="satellite-drawer-filter-sensor"
                    value={selectedEvalOption.label}
                    onChange={(e) => handleChangeEvalScript(e.target.value)}
                >
                    {evalScriptOptions.map((t, index) => {
                        return (
                            <option key={index} value={t.value}>
                                {t.label}
                            </option>
                        );
                    })}
                </Select>
                <ArrowIcon>
                    <FaArrow className="fa fa-chevron-down" />
                </ArrowIcon>
            </FilterColumn>
            <FilterColumn>
                <SelectIcon>
                    <img src="/assets/sentinel-filter/calendar.svg" />
                </SelectIcon>
                <Select
                    data-testid="satellite-drawer-filter-date"
                    value={selectedDateOption.label}
                    onChange={(e) => {
                        const displayText = e.target.value as string;
                        const date = dates.find((t) => t.displayText === displayText);
                        changeDateRange(date || last30Days);
                    }}
                >
                    {dateRangeOptions.map((t, index) => {
                        return (
                            <option key={index} value={t.value}>
                                {t.label}
                            </option>
                        );
                    })}
                </Select>
                <ArrowIcon>
                    <FaArrow className="fa fa-chevron-down" />
                </ArrowIcon>
            </FilterColumn>
        </Container>
    );
};

export default MobileSatelliteFilter;

const Container = styled.div`
    padding: 4px;
`;

const FilterColumn = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const Select = styled.select`
    width: 100%;
    appearance: none;
    outline-width: 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    display: block;
    cursor: pointer;
    pointer-events: auto;
    background-color: transparent;
    margin: 4px 0px;
    height: 38px;
    color: white;
    font-size: 0.9rem;
    padding-left: 35px;

    option {
        color: white;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &::-webkit-scrollbar {
        width: '8px';
        background-color: '#343a40';
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    :focus,
    :active {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.87);
    }
`;

const SelectIcon = styled.div`
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ArrowIcon = styled.div`
    position: absolute;
    right: 15px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(204, 204, 204); // react-select color
    padding-left: 10px;
`;

const FaArrow = styled.i`
    color: rgb(204, 204, 204); //  // react-select color
    font-size: 1.2rem;
    margin-top: 3px;
`;
