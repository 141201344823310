import React from 'react';
import styled from 'styled-components';

import { UserDTO } from '../../../../../api/model';
import SoarHelper from '../../../../../lib/soar-helper';
import MathUtil from '../../../../../lib/math-util';

interface ProfileStatsProps {
    userProfile: UserDTO;
}

const ProfileStats = (props: ProfileStatsProps) => {
    const mapCount = props.userProfile.totalMaps ?? 0;
    const totalLikes = props.userProfile.totalLikes ?? 0;
    const mappedArea = props.userProfile.areaMappedInMeters ?? 0;

    return (
        <ProfileStatsContainer>
            {mapCount > 0 && (
                <ProfileStat>
                    <ProfileStatIcon src="/assets/user-profile-logos/maps.png" alt="Maps icon" />
                    <ProfileStatValue title={`${mapCount.toLocaleString()} Maps`}>
                        {mapCount.toLocaleString()}
                    </ProfileStatValue>
                    <ProfileStatLabel>Maps</ProfileStatLabel>
                </ProfileStat>
            )}
            {totalLikes > 0 && SoarHelper.isSoar() && (
                <React.Fragment>
                    <ProfileStatDivider />
                    <ProfileStat>
                        <ProfileStatIcon src="/assets/user-profile-logos/likes.png" alt="Likes icon" />
                        <ProfileStatValue title={`${totalLikes.toLocaleString()} Likes`}>
                            {totalLikes.toLocaleString()}
                        </ProfileStatValue>
                        <ProfileStatLabel>Likes</ProfileStatLabel>
                    </ProfileStat>
                </React.Fragment>
            )}
            {mappedArea > 0 && (
                <React.Fragment>
                    <ProfileStatDivider />
                    <ProfileStat>
                        <ProfileStatIcon src="/assets/user-profile-logos/covered.png" alt="Mapped Area icon" />
                        <ProfileStatValue title={`${mappedArea.toLocaleString()} km²`}>
                            {MathUtil.formatArea(mappedArea)} km<sup>2</sup>
                        </ProfileStatValue>
                        <ProfileStatLabel>Mapped Area</ProfileStatLabel>
                    </ProfileStat>
                </React.Fragment>
            )}
        </ProfileStatsContainer>
    );
};

export default ProfileStats;

const ProfileStatDivider = styled.div`
    border-left: 1px solid white;
    height: 50px;
    opacity: 0.15;
`;

const ProfileStatsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 0;

    ${ProfileStatDivider}:first-child {
        display: none;
    }
`;

const ProfileStat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 100px;

    * {
        user-select: none;
    }
`;
const ProfileStatIcon = styled.img`
    width: 22px;
    height: 22px;
`;
const ProfileStatValue = styled.strong`
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: center;
    color: white;
`;
const ProfileStatLabel = styled.span`
    font-size: ${(props) => props.theme.fontSize.small};
    text-align: center;
    color: white;
    opacity: 0.5;
`;
