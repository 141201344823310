import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionSentinelChangeOpacity, actionSentinelOpenShare } from '../../../store/Map/Sentinel/actions';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import {
    selectSentinelSelectedFeatureOpacity,
    selectSentinelOpacity,
    selectSentinelError,
} from '../../../store/Map/Sentinel/selectors';
import React from 'react';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import SentinelUtil from '../../../lib/sentinel-util';

const moment = require('moment');

interface SentinelItemProps {
    feature: SentinelFeature;
}

const MobileSentinelSearchResult = (props: SentinelItemProps) => {
    const sentinelSelectedFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sentinelOpacity = useSelector(selectSentinelOpacity);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();
    const openShare = () => dispatch(actionSentinelOpenShare());

    // this should set the selected satellite's opacity when exiting from draw tools
    useEffect(() => {
        if (sentinelSelectedFeatureOpacity && !sentinelOpacity[props.feature.id]) {
            dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity: sentinelSelectedFeatureOpacity }));
        }
    }, [dispatch, sentinelSelectedFeatureOpacity, props.feature.id, sentinelOpacity]);

    return (
        <SentinelResultsContainer>
            {props.feature ? (
                <React.Fragment>
                    <Divider />
                    <Description>
                        <DescriptionText>
                            <strong>Satellite Provider:</strong>{' '}
                            {UserHelper.formatToUppercaseAndLowercaseText(props.feature.satellite)} (
                            {SentinelUtil.isLandsatSatellite(props.feature.satellite) ? 'Landsat' : 'Sentinel'})
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Date Captured:</strong> {moment(props.feature.date).format('MMMM Do, YYYY')}
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Type:</strong> {props.feature.evalScript?.name}
                        </DescriptionText>
                        <DescriptionText>
                            <strong>Resolution:</strong> {props.feature.resolution}m/pixel
                        </DescriptionText>
                    </Description>
                    <ShareButton
                        title="Share"
                        onClick={() => {
                            openShare();
                            Analytics.Event('Mobile Sentinel', 'Clicked share');
                        }}
                        disabled={sentinelError ? true : false}
                    >
                        <ShareButtonIcon
                            src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3E%3Cpath d='M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z'/%3E%3C/svg%3E`}
                        />
                        Share
                    </ShareButton>
                </React.Fragment>
            ) : null}
        </SentinelResultsContainer>
    );
};

export default MobileSentinelSearchResult;

const SentinelResultsContainer = styled.div`
    user-select: none;
    margin: 5px 10px;
`;

const Divider = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.color.mobile.divider};
    margin: 20px 0px;
`;

const Description = styled.div`
    margin: 10px 0px;
`;

const DescriptionText = styled.p`
    color: ${(props) => props.theme.color.white};

    strong {
        color: inherit;
    }
`;

const ShareButton = styled.button`
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    min-width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    pointer-events: auto;
    color: white;
    font-size: 16px;
`;

const ShareButtonIcon = styled.img`
    height: 20px;
    margin: 0 6px 0 2px;
`;
