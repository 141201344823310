import { actionTypes as at } from './constants';
import { SentinelFeature } from './model';
import { LatLngBounds } from 'leaflet';
import { Action } from '../../root-reducer';

interface StoreMapSentinel {
    sentinelIsBoxSelectActive: boolean;
    sentinelSelectedFeature: SentinelFeature | undefined;
    sentinelSelectedFeatureOpacity: number | undefined;
    sentinelIsDownloadOpen: boolean;
    sentinelIsShareOpen: boolean;
    sentinelSelectedAOI: LatLngBounds | undefined;
    sentinelMapZoom: number;
    sentinelFeatures: SentinelFeature[] | undefined;
    sentinelFeaturesLoading: boolean;
    sentinelError: Error | undefined;
    sentinelLayerOpacity: { [id: number]: number };
    sentinelFeatureLoading: boolean;
}

const initialState: StoreMapSentinel = {
    sentinelIsBoxSelectActive: false,
    sentinelSelectedFeature: undefined,
    sentinelSelectedFeatureOpacity: undefined,
    sentinelIsDownloadOpen: false,
    sentinelIsShareOpen: false,
    sentinelSelectedAOI: undefined,
    sentinelMapZoom: 0,
    sentinelFeatures: undefined,
    sentinelFeaturesLoading: false,
    sentinelError: undefined,
    sentinelLayerOpacity: {},
    sentinelFeatureLoading: false,
};

export default (state: StoreMapSentinel = initialState, action: Action): StoreMapSentinel => {
    switch (action.type) {
        case at.SENTINEL_BEGIN_BOX_SELECT:
            return {
                ...state,
                sentinelSelectedAOI: undefined,
                sentinelIsBoxSelectActive: true,
            };

        case at.SENTINEL_END_BOX_SELECT:
            return {
                ...state,
                sentinelIsBoxSelectActive: false,
            };

        case at.SENTINEL_SELECTED_AOI:
            return {
                ...state,
                sentinelSelectedAOI: action.payload,
            };

        case at.SENTINEL_MAP_ZOOM:
            return {
                ...state,
                sentinelMapZoom: action.payload,
            };

        case at.SENTINEL_SELECTED_FEATURE:
            return {
                ...state,
                sentinelSelectedFeature: action.payload,
            };

        case at.SENTINEL_SELECTED_FEATURE_OPACITY:
            return {
                ...state,
                sentinelSelectedFeatureOpacity: action.payload,
            };

        case at.SENTINEL_RESET_SELECTED_FEATURE:
            return {
                ...state,
                sentinelSelectedFeature: undefined,
            };

        case at.SENTINEL_RESET_SELECTED_AOI:
            return {
                ...state,
                sentinelSelectedAOI: undefined,
            };

        case at.SENTINEL_OPEN_DOWNLOAD:
            return {
                ...state,
                sentinelIsDownloadOpen: true,
            };

        case at.SENTINEL_OPEN_SHARE:
            return {
                ...state,
                sentinelIsShareOpen: true,
            };

        case at.SENTINEL_SET_OPACITY:
            return {
                ...state,
                sentinelLayerOpacity: action.payload
                    ? {
                          ...state.sentinelLayerOpacity,
                          [action.payload.id]: action.payload.opacity,
                      }
                    : {},
            };

        case at.SENTINEL_CLOSE_DOWNLOAD:
            return {
                ...state,
                sentinelIsDownloadOpen: false,
            };

        case at.SENTINEL_CLOSE_SHARE:
            return {
                ...state,
                sentinelIsShareOpen: false,
            };

        case at.SENTINEL_FETCH_FEATURES:
            return {
                ...state,
                sentinelFeaturesLoading: true,
                sentinelError: undefined,
            };

        case at.SENTINEL_FETCH_FEATURES_SUCCESS:
            return {
                ...state,
                sentinelFeaturesLoading: false,
                sentinelError: undefined,
                sentinelFeatures: action.payload,
            };

        case at.SENTINEL_FETCH_FEATURES_ERROR:
            return {
                ...state,
                sentinelFeaturesLoading: false,
                sentinelError: action.payload,
                sentinelFeatures: undefined,
            };

        case at.SENTINEL_CLEAR_FEATURES:
            return {
                ...state,
                sentinelFeaturesLoading: false,
                sentinelError: undefined,
                sentinelFeatures: undefined,
                sentinelSelectedFeature: undefined,
            };

        case at.SENTINEL_FEATURE_LOADING:
            return {
                ...state,
                sentinelFeatureLoading: action.payload,
            };

        default:
            return state;
    }
};
