import React, { useEffect } from 'react';
import { LatLngBounds } from 'leaflet';
import {
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeaturesError,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
    actionSentinelSelectAOI,
} from '../../../store/Map/Sentinel/actions';
import { selectSentinelIsBoxSelectActive } from '../../../store/Map/Sentinel/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import SatelliteAOIControl from './satellite-aoi-control';
import { isValidAOI, satelliteAOIText } from './sentinel-aoi-utils';
import { SentinelImageDimension } from '../../../api/model';
import SatelliteUtil from '../../../lib/satellite-util';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import Analytics from '../../../lib/user-analytics';

const SentinelAOISelect = () => {
    const dispatch = useDispatch();
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const isAOIActive = useSelector(selectSentinelIsBoxSelectActive);

    // Separated landsat and sentinel logic for validation so easier to control but could be merged
    const validateAOI = (aoi: LatLngBounds): boolean => {
        const isLandsat = sideDrawerMode === SideDrawerMode.SATELLITE_LANDSAT;
        const isSentinel = sideDrawerMode === SideDrawerMode.SATELLITE_SENTINEL;

        if (isLandsat) {
            const quality: SentinelImageDimension[] = SatelliteUtil.landsatQualityEvaluation(aoi);

            if (quality[0].isAvailable) {
                dispatch(actionSentinelFetchFeaturesError(undefined));
                return true;
            } else {
                const errorMessage = SatelliteUtil.sentinelErrorMessageResponse(quality[0]);
                dispatch(actionSentinelFetchFeaturesError(errorMessage));
                return false;
            }
        } else {
            const quality: SentinelImageDimension[] = SatelliteUtil.sentinelQualityEvaluation(aoi);
            const sentinelValid: boolean = quality[3].isAvailable;
            if (sentinelValid && isSentinel) {
                dispatch(actionSentinelFetchFeaturesError(undefined));
                return true;
            } else {
                const errorMessage = SatelliteUtil.sentinelErrorMessageResponse(quality[3]);
                dispatch(actionSentinelFetchFeaturesError(errorMessage));
                return false;
            }
        }
    };

    const handleAOIChange = (aoi: LatLngBounds) => {
        if (isValidAOI(aoi)) {
            const isValidQuality = validateAOI(aoi);
            if (isValidQuality) {
                Analytics.Event('Main View', 'Sentinel aoi select', aoi.toBBoxString());
                dispatch(actionSentinelSelectAOI(aoi));
            }
        } else if (!aoi) {
            dispatch(actionSentinelEndBoxSelect());
        }
    };

    const handleAOIStyle = (aoi: LatLngBounds) => {
        return satelliteAOIText(aoi, sideDrawerMode);
    };

    useEffect(() => {
        return () => {
            dispatch(actionSentinelResetSelectedAOI());
            dispatch(actionSentinelResetSelectedFeature());
        };
    }, [dispatch]);

    if (!isAOIActive) {
        return <React.Fragment />;
    }

    return <SatelliteAOIControl onAOIChange={handleAOIChange} aoiDisplayStyle={handleAOIStyle} />;
};

export default SentinelAOISelect;
