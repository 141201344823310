import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SideDrawerNested from '../../../SideDrawer/Shared/side-drawer-nested';
import { useDispatch, useSelector } from 'react-redux';
import { selectSentinelFeatures, selectSentinelSelectedFeature } from '../../../../../store/Map/Sentinel/selectors';
import SentinelAdvancedControlSettings from './sentinel-advanced-control-settings';
import SentinelAdvancedControlPreview from './sentinel-advanced-control-preview';
import { trueColor } from '../sentinel-default-evalscripts';
import ApiCfSentinel, { EvalScript } from '../../../../../api/api-cf-sentinel';
import {
    actionSentinelFetchFeaturesSuccess,
    actionSentinelFeatureLoading,
    actionSentinelSelectFeature,
} from '../../../../../store/Map/Sentinel/actions';
import { actionFlyTo } from '../../../../../store/App/actions';
import { toast } from 'react-toastify';
import { SentinelOptionButton, SentinelOptionIcon } from '../sentinel-item';
import SatelliteUtil from '../../../../../lib/satellite-util';
import Analytics from '../../../../../lib/user-analytics';
import { updateShortUrl } from '../Shared/use-short-url';
import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';

interface SentinelAdvancedFeaturesProps {
    onEvalScriptUpdate?: (evalScript: EvalScript) => void;
}

const SentinelAdvancedFeatures = (props: SentinelAdvancedFeaturesProps) => {
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelResults = useSelector(selectSentinelFeatures);

    const [showAdvancedFeatures, setShowAdvancedFeatures] = useState(false);
    const [selectedEvalScript, setSelectedEvalScript] = useState<EvalScript>(
        selectedSentinelFeature?.evalScript || trueColor
    );
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [previewView, setPreviewView] = useState<L.LatLngBounds | undefined>(undefined);

    const dispatch = useDispatch();

    const handleSelectedEvalScript = async (evalScript: EvalScript) => {
        if (!selectedSentinelFeature) {
            toast.error('Feature not found');
            return;
        }

        setSelectedEvalScript(evalScript);
        const selectedFeature = {
            ...selectedSentinelFeature,
            highResolutionPreviewUrl: undefined,
            previewUrl: '',
            evalScript,
        };
        dispatch(actionSentinelSelectFeature(selectedFeature));
        updateShortUrl(selectedFeature);

        await handleUpdateSentinelFeaturesScripts(selectedFeature);

        setIsLoadingPreview(true);
        dispatch(actionSentinelFeatureLoading(true));

        const preview = await ApiCfSentinel.updateSentinelPreviewImage(selectedFeature, evalScript);

        setIsLoadingPreview(false);

        const highResFeature = await getHighResolutionImage(
            {
                ...selectedFeature,
                previewUrl: preview.imageUrl ?? '',
            },
            evalScript
        );

        dispatch(actionSentinelSelectFeature(highResFeature));
        dispatch(actionSentinelFeatureLoading(false));
        props.onEvalScriptUpdate?.(evalScript);
    };

    const handleUpdateSentinelFeaturesScripts = async (sentinelFeature: SentinelFeature) => {
        if (!sentinelResults) {
            return;
        }

        const updateFeatureDetails = sentinelResults.map((feature) => {
            return {
                ...feature,
                evalScript: sentinelFeature.evalScript,
                resolution: sentinelFeature.resolution,
            };
        });

        dispatch(actionSentinelFetchFeaturesSuccess(updateFeatureDetails));
    };

    const getHighResolutionImage = async (feature: SentinelFeature, evalScript: EvalScript) => {
        const selectedQuality = SatelliteUtil.getSelectedQuality(feature, feature?.resolution as number);
        const { imageUrl, error } = await ApiCfSentinel.updateSentinelImage(
            feature,
            evalScript,
            selectedQuality?.widthPixels ?? 0,
            selectedQuality?.heightPixels ?? 0
        );

        if (error) {
            toast.error('Your script looks like it may be incorrect, please try again');
        }

        return {
            ...feature,
            highResolutionPreviewUrl: imageUrl ?? '',
            resolution: feature.resolution,
        };
    };

    const handleShowAdvancedFeatures = () => {
        Analytics.Event(
            'Satellite - Sentinel',
            `Clicked Advanced to ${showAdvancedFeatures ? 'show' : 'hide'} advanced features`,
            `${selectedSentinelFeature?.evalScript?.name} - ${selectedSentinelFeature?.bbox.toBBoxString()}`
        );
        setShowAdvancedFeatures(true);
    };

    useEffect(() => {
        if (selectedSentinelFeature) {
            setSelectedEvalScript(selectedSentinelFeature?.evalScript || trueColor);
        }
    }, [selectedSentinelFeature, selectedSentinelFeature?.evalScript]);

    useEffect(() => {
        if (!showAdvancedFeatures && previewView) {
            dispatch(actionFlyTo(previewView, undefined, true));
        }
    }, [showAdvancedFeatures, previewView, dispatch]);

    return (
        <React.Fragment>
            <SideDrawerNested
                key={`${selectedSentinelFeature?.id}-advanced-features`}
                closeDrawer={!showAdvancedFeatures}
                width={'calc(100vw - (500px - 95px))'} // width - sidedrawer - icon width
                controlText={`Advanced Sentinel Satellite Image Processing`}
                controlComponent={
                    <SentinelOptionButton onClick={() => handleShowAdvancedFeatures()}>
                        Advanced
                        <SentinelOptionIcon src="/assets/side-drawer/advanced-sentinel-wand-black.png" />
                    </SentinelOptionButton>
                }
                hideIcon={true}
            >
                <SentinelAdvancedControlContainer>
                    {selectedSentinelFeature ? (
                        <React.Fragment>
                            <SentinelAdvancedControlSettings
                                setShowAdvancedFeatures={() => setShowAdvancedFeatures(false)}
                                selectedEvalScript={selectedEvalScript}
                                onSelectedEvalScript={handleSelectedEvalScript}
                                isLoadingPreview={isLoadingPreview}
                            />
                            <SentinelAdvancedControlPreview
                                feature={{
                                    ...selectedSentinelFeature,
                                    evalScript: selectedEvalScript,
                                }}
                                setShowAdvancedFeatures={() => setShowAdvancedFeatures(false)}
                                onMapViewChange={(bounds) => setPreviewView(bounds)}
                            />
                        </React.Fragment>
                    ) : null}
                </SentinelAdvancedControlContainer>
            </SideDrawerNested>
        </React.Fragment>
    );
};

export default SentinelAdvancedFeatures;

const SentinelAdvancedControlContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 10px 0px 10px;
`;
