import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import { useDispatch } from 'react-redux';
import {
    actionSentinelFetchFeaturesSuccess,
    actionSentinelSelectFeature,
} from '../../../../store/Map/Sentinel/actions';
import SideDrawerSelect from '../../SideDrawer/Shared/side-drawer-select';
import { updateShortUrl } from './Shared/use-short-url';
import { EvalScript } from '../../../../api/api-cf-sentinel';
import { DateRange, getDateOptions, last30Days } from './Shared/date-filter-util';

interface SentinelFilterProps {
    onSelectEvalScript: (evalScript: EvalScript) => void;
    evalScript: EvalScript;
    dateRange: DateRange;
    onSelectDateRange: (dateRange: DateRange) => void;
    defaultEvalScriptOptions: EvalScript[];
}

const SentinelDrawerFilter = (props: SentinelFilterProps) => {
    const [dates, setDates] = useState<DateRange[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateRange>(props.dateRange);
    const [selectedEvalScript, setSelectedEvalScript] = useState<EvalScript>(props.evalScript);

    const dispatch = useDispatch();

    const changeDateRange = (date: DateRange) => {
        Analytics.Event('Satellite - Sentinel', 'Changed Date', date.displayText);
        setSelectedDate(date);
        props.onSelectDateRange(date);
    };

    const handleChangeEvalScript = (scriptName: string) => {
        Analytics.Event('Satellite - Sentinel', 'Changed EvalScript', scriptName);
        const selectedScript = props.defaultEvalScriptOptions.find((script) => script.name === scriptName);
        if (!selectedScript) return;
        updateShortUrl();
        dispatch(actionSentinelFetchFeaturesSuccess(undefined));
        dispatch(actionSentinelSelectFeature(undefined));

        props.onSelectEvalScript(selectedScript);
    };

    const evalScriptOptions = [
        ...props.defaultEvalScriptOptions.map((evalOption) => ({
            value: evalOption.name,
            label: evalOption.name,
            evalScript: evalOption,
        })),
    ];

    if (!evalScriptOptions.some((opt) => opt.value === selectedEvalScript?.name)) {
        evalScriptOptions.push({
            value: 'Custom Script',
            label: 'Custom Script',
            evalScript: selectedEvalScript,
        });
    }

    const selectedEvalOption = evalScriptOptions.find(
        (option) => option.evalScript.name === selectedEvalScript?.name
    ) || {
        value: 'Custom Script',
        label: 'Custom Script',
    };

    const dateRangeOptions = dates.map((date) => ({
        value: date.displayText,
        label: date.displayText,
    }));

    const selectedDateOption = dateRangeOptions.find((option) => option.value === selectedDate.displayText) || {
        value: last30Days.displayText,
        label: last30Days.displayText,
    };

    const dateOptions = useCallback(() => {
        return getDateOptions();
    }, []);

    useEffect(() => {
        setDates(dateOptions());
    }, [dateOptions]);

    useEffect(() => {
        if (props.evalScript) {
            setSelectedEvalScript(props.evalScript);
        }
    }, [props.evalScript]);

    useEffect(() => {
        if (props.dateRange) {
            setSelectedDate(props.dateRange);
        }
    }, [props.dateRange]);

    return (
        <Container>
            <FilterColumn>
                <SideDrawerSelect
                    options={evalScriptOptions}
                    value={selectedEvalOption}
                    onChange={(selectedEvalOption) =>
                        selectedEvalOption && handleChangeEvalScript(selectedEvalOption.value)
                    }
                    icon="/assets/sentinel-filter/layers.svg"
                />
            </FilterColumn>
            <FilterColumn>
                <SideDrawerSelect
                    options={dateRangeOptions}
                    value={selectedDateOption}
                    onChange={(selectedDateOption) => {
                        const date = dates.find((t) => t.displayText === selectedDateOption.value);
                        changeDateRange(date || last30Days);
                    }}
                    icon="/assets/sentinel-filter/calendar.svg"
                />
            </FilterColumn>
        </Container>
    );
};

export default SentinelDrawerFilter;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 5px;
`;

const FilterColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
`;
