import styled from 'styled-components';
import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';
import moment from 'moment';
import GeoUtil from '../../../../../lib/geo-util';
import SideDrawerLocationMap from '../../../SideDrawer/Shared/side-drawer-location-map';
import SentinelResolutionAndDownloadSelector from './sentinel-resolution-and-download-selector';
import { updateShortUrl } from '../Shared/use-short-url';
import Analytics from '../../../../../lib/user-analytics';

interface SentinelAdvancedControlPreviewProps {
    feature: SentinelFeature;
    setShowAdvancedFeatures: () => void;
    onMapViewChange?: (bounds: L.LatLngBounds) => void;
}

const SentinelAdvancedControlPreview = ({
    feature,
    setShowAdvancedFeatures,
    onMapViewChange,
}: SentinelAdvancedControlPreviewProps) => {
    return (
        <SentinelMapPreviewContainer>
            <MapSearchResultsTitle title={moment(feature.date).format('MMM Do YYYY')}>
                {feature.evalScript?.name
                    ? `${feature.evalScript.name} image acquired on ${moment(feature.date).format('MMM Do YYYY')}`
                    : `${feature.satellite} image acquired on ${moment(feature.date).format('MMM Do YYYY')}`}
            </MapSearchResultsTitle>
            <CloseIcon
                onClick={() => {
                    setShowAdvancedFeatures();
                    updateShortUrl(feature);
                    Analytics.Event('Satellite - Sentinel', 'Clicked to close Advanced View');
                }}
                className="fa fa-close"
                expanded={feature !== undefined}
                data-testid="sentinel-advanced-control-close-button"
                title="Close & go back to Standard View"
            />
            <SideDrawerLocationMap
                position={GeoUtil.latLngBoundsToWKT(feature.bbox)}
                imageOverlay={feature.highResolutionPreviewUrl || feature.previewUrl}
                width={'100%'}
                height={'calc(100vh - 165px)'}
                mapBoundsPadding={0.05}
                borderRadius={true}
                margin={0}
                displayControls
                onMapViewChange={onMapViewChange}
            />
            <SentinelResolutionAndDownloadSelector feature={feature} />
        </SentinelMapPreviewContainer>
    );
};

export default SentinelAdvancedControlPreview;

const SentinelMapPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

const MapSearchResultsTitle = styled.h2`
    margin: 15px 20px 10px 0px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
`;

const CloseIcon = styled.i<{ expanded: boolean }>`
    position: absolute;
    right: 20px;
    top: 35px;
    padding: 8px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: opacity 0.5s ease;
    animation: ${({ expanded }) => (expanded ? 'fadeCloseIconIn' : 'fadeCloseIconOut')} 0.5s ease;
    align-self: flex-end;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;

    @keyframes fadeCloseIconIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeCloseIconOut {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
