import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { selectSentinelFeatureLoading } from '../../../../../store/Map/Sentinel/selectors';
import { lightDropShadow } from '../../../../../styles/style';
import { PulseLoader } from '../../../../Shared/pulse-loader';

const SentinelAdvancedPreviewLoader = () => {
    const isLoadingPreview = useSelector(selectSentinelFeatureLoading);

    return (
        <ToastContainer isLoading={isLoadingPreview} className="tile-loader">
            <PulseLoader />
        </ToastContainer>
    );
};

export default SentinelAdvancedPreviewLoader;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const ToastContainer = styled.div<{ isLoading: boolean }>`
    position: absolute;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.8);
    left: calc(50% - 75px);
    top: 5px;
    width: 150px;
    height: 45px;
    border-radius: 6px;
    animation: ${({ isLoading }) => (isLoading ? fadeIn : fadeOut)} 0.5s ease-in-out both;
    ${lightDropShadow};
`;
