import styled from 'styled-components';
import { UserDTO } from '../../../api/model';
import prettyBytes from 'pretty-bytes';
import MathUtil from '../../../lib/math-util';

interface ProfileProps {
    myProfile?: UserDTO;
}

const MenuUserDetails = (props: ProfileProps) => {
    const mapCount = props.myProfile?.totalMaps ?? 0;
    const areaMapped = props.myProfile?.areaMappedInMeters ?? 0;
    const totalStorageUsed = props.myProfile?.totalStorage ?? 0;

    return (
        <ProfileUserDetails>
            {props.myProfile ? (
                <ProfileUserName>{`Hello, ${
                    props.myProfile.firstName ? props.myProfile.firstName : 'Soar User'
                }`}</ProfileUserName>
            ) : null}
            <ProfileUserStats>
                <ProfileFileStatsContainer>
                    <ProfileFileStats title={`${mapCount.toLocaleString()} Total Maps`}>
                        {mapCount.toLocaleString()}
                        <ProfileFileStatsLabel>Total Maps</ProfileFileStatsLabel>
                    </ProfileFileStats>
                    <Divider />
                </ProfileFileStatsContainer>
                <ProfileFileStatsContainer>
                    <ProfileFileStats title={`${areaMapped.toLocaleString()} km² Area Mapped`}>
                        {MathUtil.formatArea(areaMapped)} km<sup>2</sup>
                        <ProfileFileStatsLabel>Area Mapped</ProfileFileStatsLabel>
                    </ProfileFileStats>
                    <Divider />
                </ProfileFileStatsContainer>
                <ProfileFileStatsContainer>
                    <ProfileFileStats title={`${totalStorageUsed.toLocaleString()} Bytes Storage Used`}>
                        {prettyBytes(totalStorageUsed)}
                        <ProfileFileStatsLabel>Storage Used</ProfileFileStatsLabel>
                    </ProfileFileStats>
                </ProfileFileStatsContainer>
            </ProfileUserStats>
        </ProfileUserDetails>
    );
};

export default MenuUserDetails;

const ProfileUserDetails = styled.div`
    padding-top: 10px;
    color: white;
    width: 330px;
`;

const ProfileUserName = styled.h1`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    padding: 0px 8px;
    color: inherit;
    overflow-wrap: break-word;
    text-align: left;
`;

const ProfileUserStats = styled.div`
    color: inherit;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    border-top: solid 1px rgba(255, 255, 255, 0.3);
`;

const ProfileFileStatsContainer = styled.div`
    flex: 1 0 auto;
    font-size: 26px;
    color: inherit;
    text-align: center;
`;

const ProfileFileStats = styled.div`
    padding: 5px;
    margin: 5px;
    color: inherit;
`;

const ProfileFileStatsLabel = styled.div`
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
`;

const Divider = styled.div`
    border-left: 1px solid white;
    height: 50px;
    float: right;
    opacity: 0.15;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: -60px;
`;
