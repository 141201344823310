import L, { LatLng, LatLngBounds, Map } from 'leaflet';
import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { ImageOverlay, MapContainer, Polygon, useMap } from 'react-leaflet';
import ZoomControl from '../../../MapView/Annotations/Zoom/zoom-control';
import GeoUtil, { wktPolygonToLatLngArray } from '../../../../lib/geo-util';
import Basemaps from '../../../MapView/basemaps';
import styled from 'styled-components';
import { useWindowSize } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
import { selectSentinelSelectedFeatureOpacity } from '../../../../store/Map/Sentinel/selectors';
import SentinelAdvancedPreviewLoader from '../../Satellites/Sentinel/SentinelAdvanced/sentinel-advanced-preview-loader';
import MapScaleControl from '../../../MapView/MapScale/map-scale-control';
import Annotations from '../../../MapView/Annotations/annotations';

const MinimapViewDispatcher = (props: { onMapViewChange?: (bounds: L.LatLngBounds) => void }) => {
    const map = useMap();

    const fixAntiMeridianIfRequired = useCallback(() => {
        if (map) {
            const center = map.getCenter();
            const zoom = map.getZoom();

            if (zoom >= 5) {
                const wrappedCenter = center.wrap();

                if (!wrappedCenter.equals(center)) {
                    map.flyTo(wrappedCenter, zoom);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!map) {
            console.error('ERROR: Attempted to handle map move without map');
            return;
        }

        const mapViewChange = () => {
            fixAntiMeridianIfRequired();
            props.onMapViewChange?.(map.getBounds());
        };

        map.on('moveend', mapViewChange);
        map.on('zoomend', mapViewChange);

        return () => {
            map.off('moveend', mapViewChange);
            map.off('zoomend', mapViewChange);
        };
    }, [fixAntiMeridianIfRequired, map, props]);

    return <React.Fragment />;
};

const MinimapControl = ({
    position,
    imageOverlay,
    imageBounds,
    width,
    height,
    mapBoundsPadding,
    borderRadius,
    onMapViewChange,
}: {
    position: LatLng[];
    imageOverlay?: string;
    imageBounds?: LatLngBounds;
    width?: string | number;
    height?: string | number;
    mapBoundsPadding: number;
    borderRadius?: boolean;
    onMapViewChange?: (bounds: L.LatLngBounds) => void;
}) => {
    const sentinelOverlayOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const minimapRef = useRef<Map>(null);
    const hasZoomedRef = useRef(false);
    const windowSize = useWindowSize();
    const mapContainerSize =
        width && height ? { width, height } : { width: 465, height: windowSize[1] > 900 ? 400 : 200 };

    const calculatedPositionCenter = (positions: LatLng[]): LatLng => {
        const latSum = positions?.reduce((sum, pos) => sum + pos.lat, 0);
        const lngSum = positions?.reduce((sum, pos) => sum + pos.lng, 0);
        return new LatLng(latSum / positions.length, lngSum / positions.length);
    };

    useEffect(() => {
        const fitBoundsWithPadding = () => {
            if (minimapRef?.current && position?.length && !hasZoomedRef.current) {
                const map = minimapRef.current;
                const mapSize = map.getSize();
                const padding = Math.min(mapSize.x, mapSize.y) * mapBoundsPadding;
                const bounds = L.latLngBounds(position.map((latLng) => [latLng.lat, latLng.lng]));

                if (!padding) return;

                requestAnimationFrame(() => {
                    map.fitBounds(bounds, {
                        paddingTopLeft: [padding, padding],
                        paddingBottomRight: [padding, padding],
                    });

                    hasZoomedRef.current = true;
                });
            }
        };
        setTimeout(() => {
            fitBoundsWithPadding();
        }, 100);
    }, [mapBoundsPadding, position]);

    const minimap = useMemo(
        () => (
            <React.Fragment>
                <SentinelAdvancedPreviewLoader />
                <MapContainer
                    ref={minimapRef}
                    center={calculatedPositionCenter(position) || [0, 0]}
                    zoom={1}
                    dragging={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    attributionControl={false}
                    zoomControl={false}
                    className="leaflet-map"
                    id="leaflet-map-minimap"
                    style={{
                        width: mapContainerSize.width,
                        height: mapContainerSize.height,
                        position: 'relative',
                        borderRadius: borderRadius ? '6px' : '0',
                    }}
                >
                    <MinimapViewDispatcher onMapViewChange={onMapViewChange} />
                    <Basemaps />
                    <ZoomControl />
                    <MapScaleControl />
                    <Polygon
                        key={`${position.toString()}`}
                        positions={position}
                        weight={2}
                        color={'#EED926'}
                        fillColor={imageOverlay ? 'transparent' : 'rgba(238, 218, 38, 0.5)'}
                    />
                    {imageOverlay && imageBounds ? (
                        <React.Fragment>
                            <ImageOverlay
                                key={imageOverlay}
                                url={imageOverlay}
                                zIndex={1}
                                interactive={false}
                                bounds={imageBounds}
                                opacity={sentinelOverlayOpacity}
                            />
                            <Annotations editMode={false} setEditMode={() => ''} />
                        </React.Fragment>
                    ) : null}
                </MapContainer>
            </React.Fragment>
        ),
        [
            borderRadius,
            imageBounds,
            imageOverlay,
            mapContainerSize.height,
            mapContainerSize.width,
            onMapViewChange,
            position,
            sentinelOverlayOpacity,
        ]
    );

    return minimap;
};

interface SidedrawerLocationMapProps {
    position?: string;
    imageOverlay?: string;
    width?: string | number;
    height?: string | number;
    mapBoundsPadding?: number;
    borderRadius?: boolean;
    margin?: number;
    displayControls?: boolean;
    onMapViewChange?: (bounds: L.LatLngBounds) => void;
}

const SideDrawerLocationMap = ({
    position,
    imageOverlay,
    width,
    height,
    mapBoundsPadding = 0.1, // 10% padding around the bounds
    borderRadius,
    margin = 10,
    displayControls = false,
    onMapViewChange,
}: SidedrawerLocationMapProps) => {
    if (!position) return <React.Fragment />;

    const bounds = wktPolygonToLatLngArray(position) as LatLng[];
    const imageBounds = GeoUtil.latLngBoundsFromPolygonWKT(position);
    return (
        <MiniMapContainer margin={margin} displayControls={displayControls}>
            <MinimapControl
                position={bounds}
                imageOverlay={imageOverlay}
                imageBounds={imageBounds}
                width={width}
                height={height}
                borderRadius={borderRadius}
                mapBoundsPadding={mapBoundsPadding}
                onMapViewChange={onMapViewChange}
            />
        </MiniMapContainer>
    );
};

export default SideDrawerLocationMap;

const MiniMapContainer = styled.div<{ margin: number; displayControls: boolean }>`
    margin-bottom: ${({ margin }) => margin}px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    ${({ displayControls }) =>
        !displayControls &&
        `
        .leaflet-control {
            display: none !important;
        }
    `}

    .stoaries-annotation-control-container {
        position: absolute;
    }

    .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        vertical-align: bottom;
        display: table-cell;
    }

    .leaflet-right .leaflet-control {
        margin: 0;
        padding: 0;
    }

    .leaflet-top.leaflet-right {
        top: 2px !important;
        right: 2px !important;

        a.leaflet-control-zoom-in,
        a.leaflet-control-zoom-out {
            background-color: rgba(0, 0, 0, 0.85) !important;
        }

        a.leaflet-control-zoom-out {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        #box-zoom-button {
            background-color: rgba(0, 0, 0, 0.85) !important;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        position: fixed;
        bottom: 28px !important;
        right: 27px !important;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.85) !important;
        padding: 0px !important;
        width: 40px;
        height: 40px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151;
        border-radius: 6px;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
        margin-bottom: 4px;
        z-index: 1000;
        padding: 12px;
        background: rgba(0, 0, 0, 0.85);

        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151 !important;
        border-radius: 6px;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded:nth-of-type(2) {
        margin-right: 2px;
        margin-bottom: 42px;
        min-width: 126px;
    }

    .leaflet-control-better-scale.leaflet-control {
        right: 58px !important;
        bottom: 10px !important;
    }
`;
